// export const API_BASE_URL = "http://localhost/sis2/back/api/auth";
// export const FILE_BASE_URL = "http://localhost/sis2/back/public/storage/";
// export const IMPORT_SAMPLE_PARENT_FILE = "http://localhost/sis2/back/public/storage/demo-imports/import-parents.xlsx";
// export const IMPORT_SAMPLE_STUDENTS_FILE = "http://localhost/sis2/back/public/storage/demo-imports/import-students.xlsx";
// export const SOCKET_ENDPOINT_URL = 'http://localhost/sis2/back/api/'
// export const WS_HOST = 'http://localhost'
// export const PUBLIC_LOGO_PATH = 'http://localhost/sis2/back/public/img/logo.png'
// export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'http://localhost/sis2/back/public/img/logo-sm.png'
// export const PUBLIC_FAVICON_LOGO_PATH = 'http://localhost/sis2/back/public/img/favicon_logo.ico'
// export const img = "/img/avatars/user.png"
// export const APP_VERSION = "1.0.6"

//test School
// export const API_BASE_URL = "https://school.threeapples.ca/back/api/auth";
// export const FILE_BASE_URL = "https://school.threeapples.ca/back/public/storage/";
// export const IMPORT_SAMPLE_PARENT_FILE = "https://school.threeapples.ca/back/public/storage/demo-imports/import-parents.xlsx";
// export const IMPORT_SAMPLE_STUDENTS_FILE = "https://school.threeapples.ca/back/public/storage/demo-imports/import-students.xlsx";
// export const SOCKET_ENDPOINT_URL = 'https://school.threeapples.ca/back/api/auth'
// export const WS_HOST = 'https://school.threeapples.ca/back/'
// export const PUBLIC_LOGO_PATH = 'https://school.threeapples.ca/back/public/img/logo.png'
// export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'https://school.threeapples.ca/back/public/img/logo-sm.png'
// export const PUBLIC_FAVICON_LOGO_PATH = 'https://school.threeapples.ca/back/public/img/favicon_logo.ico'
// export const img = "/img/avatars/user.png"
// export const APP_VERSION = "1.3.5"


//AES School
export const API_BASE_URL = "https://aes.threeapples.ca/back/api/auth";
export const FILE_BASE_URL = "https://aes.threeapples.ca/back/public/storage/";
export const IMPORT_SAMPLE_PARENT_FILE = "https://aes.threeapples.ca/back/public/storage/demo-imports/import-parents.xlsx";
export const IMPORT_SAMPLE_STUDENTS_FILE = "https://aes.threeapples.ca/back/public/storage/demo-imports/import-students.xlsx";
export const SOCKET_ENDPOINT_URL = 'https://aes.threeapples.ca/back/api/auth'
export const WS_HOST = 'https://aes.threeapples.ca/back/'

export const PUBLIC_LOGO_PATH = 'https://aes.threeapples.ca/back/public/img/logo.png'
export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'https://aes.threeapples.ca/back/public/img/logo-sm.png'
export const PUBLIC_FAVICON_LOGO_PATH = 'https://aes.threeapples.ca/back/public/img/favicon_logo.ico'
export const img = "/img/avatars/user.png"
export const APP_VERSION = "1.0.8"


//AMS School
// export const API_BASE_URL = "https://ams.threeapples.ca/back/api/auth";
// export const FILE_BASE_URL = "https://ams.threeapples.ca/back/public/storage/";
// export const IMPORT_SAMPLE_PARENT_FILE = "https://ams.threeapples.ca/back/public/storage/demo-imports/import-parents.xlsx";
// export const IMPORT_SAMPLE_STUDENTS_FILE = "https://ams.threeapples.ca/back/public/storage/demo-imports/import-students.xlsx";
// export const SOCKET_ENDPOINT_URL = 'https://ams.threeapples.ca/back/api'
// export const WS_HOST = 'https://ams.threeapples.ca/back/'

// export const PUBLIC_LOGO_PATH = 'https://ams.threeapples.ca/back/public/img/logo.png'
// export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'https://ams.threeapples.ca/back/public/img/logo-sm.png'
// export const PUBLIC_FAVICON_LOGO_PATH = 'https://ams.threeapples.ca/back/public/img/favicon_logo.ico'
// export const img = "/img/avatars/user.png"
// export const APP_VERSION = "1.0.5"


//GSS School
// export const API_BASE_URL = "https://gss.threeapples.ca/back/api/auth";
// export const FILE_BASE_URL = "https://gss.threeapples.ca/back/public/storage/";
// export const IMPORT_SAMPLE_PARENT_FILE = "https://gss.threeapples.ca/back/public/storage/demo-imports/import-parents.xlsx";
// export const IMPORT_SAMPLE_STUDENTS_FILE = "https://gss.threeapples.ca/back/public/storage/demo-imports/import-students.xlsx";
// export const SOCKET_ENDPOINT_URL = 'https://gss.threeapples.ca/back/api/auth'
// export const WS_HOST = 'https://gss.threeapples.ca/back/'

// export const PUBLIC_LOGO_PATH = 'https://gss.threeapples.ca/back/public/img/logo.png'
// export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'https://gss.threeapples.ca/back/public/img/logo-sm.png'
// export const PUBLIC_FAVICON_LOGO_PATH = 'https://gss.threeapples.ca/back/public/img/favicon_logo.ico'
// export const img = "/img/avatars/user.png"
// export const APP_VERSION = "1.1.2"


//GSSBrampton School
// export const API_BASE_URL = "https://gssbrampton.threeapples.ca/back/api/auth";
// export const FILE_BASE_URL = "https://gssbrampton.threeapples.ca/back/public/storage/";
// export const IMPORT_SAMPLE_PARENT_FILE = "https://gssbrampton.threeapples.ca/back/public/storage/demo-imports/import-parents.xlsx";
// export const IMPORT_SAMPLE_STUDENTS_FILE = "https://gssbrampton.threeapples.ca/back/public/storage/demo-imports/import-students.xlsx";
// export const SOCKET_ENDPOINT_URL = 'https://gssbrampton.threeapples.ca/back/api/auth'
// export const WS_HOST = 'https://gssbrampton.threeapples.ca/back/'

// export const PUBLIC_LOGO_PATH = 'https://gssbrampton.threeapples.ca/back/public/img/logo.png'
// export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'https://gssbrampton.threeapples.ca/back/public/img/logo-sm.png'
// export const PUBLIC_FAVICON_LOGO_PATH = 'https://gssbrampton.threeapples.ca/back/public/img/favicon_logo.ico'
// export const img = "/img/avatars/user.png"
// export const APP_VERSION = "1.0.4"


//AESBarrie School
// export const API_BASE_URL = "https://aesbarrie.threeapples.ca/back/api/auth";
// export const FILE_BASE_URL = "https://aesbarrie.threeapples.ca/back/public/storage/";
// export const IMPORT_SAMPLE_PARENT_FILE = "https://aesbarrie.threeapples.ca/back/public/storage/demo-imports/import-parents.xlsx";
// export const IMPORT_SAMPLE_STUDENTS_FILE = "https://aesbarrie.threeapples.ca/back/public/storage/demo-imports/import-students.xlsx";
// export const SOCKET_ENDPOINT_URL = 'https://aesbarrie.threeapples.ca/back/api/auth'
// export const WS_HOST = 'https://aesbarrie.threeapples.ca/back/'

// export const PUBLIC_LOGO_PATH = 'https://aesbarrie.threeapples.ca/back/public/img/logo.png'
// export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'https://aesbarrie.threeapples.ca/back/public/img/logo-sm.png'
// export const PUBLIC_FAVICON_LOGO_PATH = 'https://aesbarrie.threeapples.ca/back/public/img/favicon_logo.ico'
// export const img = "/img/avatars/user.png"
// export const APP_VERSION = "1.0.3"